body {
	margin: 0 !important;
	background-color: white;
	color: black;
	font-size: 1.2em;
	font-family: "San Francisco";
}

h1 {
	font-family: "San Francisco";
}

.main {
	padding: 30px;
	margin: 0 !important;
}

.mobile-main {
	padding: 15px;
}

.main-text {
	margin-left: 10px;
	font-size: 0.8em;
	width: 420px;
}

.main-text-mobile {
	margin-left: 10px;
	width: 80vw;
}

.main-news {
	margin-left: 10px;
	width: 600px;
	max-height: 80vh;
	overflow: scroll;
}

.main-news-mobile {
	margin-left: 10px;
	width: 80vw;
}

.main-news ul {
	padding-inline-start: 0px !important;
}

.main-text p {
	text-align: justify;
}

.menu {
	position: relative;
	width: 280px;
}

.menus {
	display: flex;
}

li {
   list-style-type: none;
}

li:hover {
	cursor: pointer;
	background-color: yellow;
}

.menu li {
	border: 1px solid black;
	margin: 5px 0px 5px 0px;
	padding: 10px;
	width: 200px;
}

.menu-span {
	width: 100%;
}

.news-list-mobile li {
	width: 100%;
	border: 1px solid black;
	margin: 5px 0px 5px 0px;
	padding: 15px;
}

.news-list li {
	border: 1px solid black;
	margin: 5px 0px 5px 0px;
	padding: 15px;
}

.project-list li {
	border: 1px solid black;
	margin: 5px 0px 5px 0px;
	padding: 10px;
	width: 400px;
}

.project-list-mobile li {
	border: 1px solid black;
	margin: 5px 0px 5px 0px;
	padding: 10px;
	width: 70vw;
}

.project-list {
	padding-inline-start: 0px !important;
	margin-left: 0px !important;
}

.news {
	top: 400px;
	position: absolute;
}

.header-image {
	max-height: 150px;
	max-width: 200px;
/*	margin-left: 40px;*/
}

.image-caption {
	color: black;
	top: -20px;
	font-size: 0.8em;
	position: relative;
	padding-bottom: 10px;
}

a {
	color: #0099ff;
	text-decoration: none;
}

ul {
	margin-left: 0px !important;
	padding-inline-start: 0px !important;
}

.main-gallery {
	overflow: scroll;
	max-height: 77vh;
	width: 60vw;
}

.main-gallery img {
	margin-top: -30px;
	max-width: 90%;
	max-height: 450px;
}

.home-link {
	color: black;
}

.home-link:hover {
	color: #0099ff;
}

.quotes {
	width: 200px;
	margin-left: 50px;
}
