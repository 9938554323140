.content {
	min-width: 60vw;
}

@font-face {
font-family: 'Bespoke-LYw8FRlPxf';
src: url('/fonts/Bespoke-LYw8FRlPxf_webfont/Bespoke-LYw8FRlPxf.eot');
src: url('/fonts/Bespoke-LYw8FRlPxf_webfont/Bespoke-LYw8FRlPxf.eot?#iefix') format('embedded-opentype'),
   url('/fonts/Bespoke-LYw8FRlPxf_webfont/Bespoke-LYw8FRlPxf.woff') format('woff'),
   url('/fonts/Bespoke-LYw8FRlPxf_webfont/Bespoke-LYw8FRlPxf.ttf') format('truetype'),
   url('/fonts/Bespoke-LYw8FRlPxf_webfont/Bespoke-LYw8FRlPxf.svg#Bespoke-LYw8FRlPxf') format('svg');
}

@import url('https://fonts.googleapis.com/css2?family=Nabla&family=Roboto&display=swap');

@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}
