.about-holder {
	display: flex;
}

.quote-list li {
	margin-bottom: 20px;
}

.quote-list a {
	color: black;
}

.quote-list a:hover {
	color: inherit;
	background-color: none;
}

.quote-source {
	margin-top: -10px;
	position: relative;
	font-size: 0.6em;
}