.project-section {
	padding: 10px;
	border: 1px solid black;
	margin: 10px;
	margin-bottom: 10px;
}

.project-section img {
	max-width: 100%;
}


.project {
	max-width: 80vw;
	display: flex;
	height: calc(95vh - 180px);
}

.nav-container {
	display: flex;
}

.nav-container div {
	padding: 10px;
	border: 1px solid black;
	margin-left: 10px;
	margin-bottom: 10px;
}

.intext-list {
	padding-block-start: 0px !important;
	padding-inline-start: 10px !important;
	margin-left: 0px;
}

.project-body {
	overflow: scroll;
	font-size: 0.8em;
	width: 450px;
	margin-right: 20px;
}

.project-gallery {
	overflow: scroll;
	width: 50vw;
	padding: 10px;
}

.project-gallery img {
	max-width: 95%;
}