@import url(https://fonts.googleapis.com/css2?family=Nabla&family=Roboto&display=swap);
body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
	min-width: 60vw;
}

@font-face {
font-family: 'Bespoke-LYw8FRlPxf';
src: url('/fonts/Bespoke-LYw8FRlPxf_webfont/Bespoke-LYw8FRlPxf.eot');
src: url('/fonts/Bespoke-LYw8FRlPxf_webfont/Bespoke-LYw8FRlPxf.eot?#iefix') format('embedded-opentype'),
   url('/fonts/Bespoke-LYw8FRlPxf_webfont/Bespoke-LYw8FRlPxf.woff') format('woff'),
   url('/fonts/Bespoke-LYw8FRlPxf_webfont/Bespoke-LYw8FRlPxf.ttf') format('truetype'),
   url('/fonts/Bespoke-LYw8FRlPxf_webfont/Bespoke-LYw8FRlPxf.svg#Bespoke-LYw8FRlPxf') format('svg');
}

@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

.news-container {
	display: flex;
	width: 65vw;
	padding: 10px;
	flex-wrap: wrap;
}

.news-item {
	width: 200px;
	min-height: 200px;
	border: 1px solid black;
	margin: 10px;
	position: relative;
	transition: 0.5s;
	font-size: 0.8em;
	align-self: flex-start;
	background-color: white;
}

.news-item > p {
	padding: 2px;
}

.news-item img {
	width: 100%;
}

.news-item-mobile {
	width: 150px;
	min-height: 150px;
	border: 1px solid black;
	margin: 10px;
	font-size: 0.8em;
	align-self: flex-start;
	background-color: white;
}

.news-container-mobile {
	display: flex;
	width: 95vw;
	padding: 2px;
	flex-wrap: wrap;
}

.news-item-mobile img {
	width: 100%;
}

.news-item-mobile > p {
	padding: 5px;
}


/*.overlay-text {
	position: absolute;
	color: white;
	z-index: 2;
	width: 300px;
}*/
.about-holder {
	display: flex;
}

.quote-list li {
	margin-bottom: 20px;
}

.quote-list a {
	color: black;
}

.quote-list a:hover {
	color: inherit;
	background-color: none;
}

.quote-source {
	margin-top: -10px;
	position: relative;
	font-size: 0.6em;
}
body {
	margin: 0 !important;
	background-color: white;
	color: black;
	font-size: 1.2em;
	font-family: "San Francisco";
}

h1 {
	font-family: "San Francisco";
}

.main {
	padding: 30px;
	margin: 0 !important;
}

.mobile-main {
	padding: 15px;
}

.main-text {
	margin-left: 10px;
	font-size: 0.8em;
	width: 420px;
}

.main-text-mobile {
	margin-left: 10px;
	width: 80vw;
}

.main-news {
	margin-left: 10px;
	width: 600px;
	max-height: 80vh;
	overflow: scroll;
}

.main-news-mobile {
	margin-left: 10px;
	width: 80vw;
}

.main-news ul {
	padding-inline-start: 0px !important;
}

.main-text p {
	text-align: justify;
}

.menu {
	position: relative;
	width: 280px;
}

.menus {
	display: flex;
}

li {
   list-style-type: none;
}

li:hover {
	cursor: pointer;
	background-color: yellow;
}

.menu li {
	border: 1px solid black;
	margin: 5px 0px 5px 0px;
	padding: 10px;
	width: 200px;
}

.menu-span {
	width: 100%;
}

.news-list-mobile li {
	width: 100%;
	border: 1px solid black;
	margin: 5px 0px 5px 0px;
	padding: 15px;
}

.news-list li {
	border: 1px solid black;
	margin: 5px 0px 5px 0px;
	padding: 15px;
}

.project-list li {
	border: 1px solid black;
	margin: 5px 0px 5px 0px;
	padding: 10px;
	width: 400px;
}

.project-list-mobile li {
	border: 1px solid black;
	margin: 5px 0px 5px 0px;
	padding: 10px;
	width: 70vw;
}

.project-list {
	padding-inline-start: 0px !important;
	margin-left: 0px !important;
}

.news {
	top: 400px;
	position: absolute;
}

.header-image {
	max-height: 150px;
	max-width: 200px;
/*	margin-left: 40px;*/
}

.image-caption {
	color: black;
	top: -20px;
	font-size: 0.8em;
	position: relative;
	padding-bottom: 10px;
}

a {
	color: #0099ff;
	text-decoration: none;
}

ul {
	margin-left: 0px !important;
	padding-inline-start: 0px !important;
}

.main-gallery {
	overflow: scroll;
	max-height: 77vh;
	width: 60vw;
}

.main-gallery img {
	margin-top: -30px;
	max-width: 90%;
	max-height: 450px;
}

.home-link {
	color: black;
}

.home-link:hover {
	color: #0099ff;
}

.quotes {
	width: 200px;
	margin-left: 50px;
}

.too-much {
	transform: rotate(-4deg);
	width: 650px;
	padding: 15px;
	background-color: white;
	color: black;
	z-index: 10;
	position: absolute;
	left: 200px;
	top: 100px;
	height: 75vh;
	overflow: scroll;
	box-shadow:     
	0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #ffe6b3,
    0 0 82px #ffcc66,
    0 0 92px #ffcc66,
    0 0 102px #e69900,
    0 0 151px #e69900;
}

.too-much-mobile {
	transform: rotate(-2deg);
	width: 80vw;
	padding: 10px;
	background-color: white;
	color: black;
	z-index: 10;
	position: absolute;
	left: 30px;
	top: 150px;
	height: 70vh;
	overflow: scroll;
	box-shadow:     
	0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #ffe6b3,
    0 0 82px #ffcc66,
    0 0 92px #ffcc66,
    0 0 102px #e69900,
    0 0 151px #e69900;
}

.too-much-container {
/*	background-color: black;*/
	background: linear-gradient(0deg, rgba(145,0,0,1) 0%, rgba(125,0,110,1) 100%);
	color: white;
	font-family: Times;
	width: 100vw;
	height: 100vh;
	position: fixed;
/*	overflow: scroll;*/
}

.too-much-container h1 {
	font-family: Bespoke-LYw8FRlPxf;
}

.too-much h1 {
	font-family: Bespoke-LYw8FRlPxf;
	text-align: center;
	width: 100%;
}

.too-much .main-div {
	margin: 40px;
	text-align: justify;
	font-size: 1.2em;
}

.too-much p {
	margin-top: 40px;
	font-size: 1em !important;
}

.less-much {
	transform: rotate(-4deg);
	font-size: 4em;
	position: fixed;
	left: 800px;
	top: 50px;
	z-index: 50;
	color: #0099ff;
}

.less-much:hover {
/*	color: #FFAAAA;*/
	cursor: pointer;
	text-shadow:     
	0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #ffe6b3,
    0 0 82px #ffcc66,
    0 0 92px #ffcc66,
    0 0 102px #e69900,
    0 0 151px #e69900;
}

.too-much .project-body {
	height: auto;
	overflow: scroll;
}
.project-section {
	padding: 10px;
	border: 1px solid black;
	margin: 10px;
	margin-bottom: 10px;
}

.project-section img {
	max-width: 100%;
}


.project {
	max-width: 80vw;
	display: flex;
	height: calc(95vh - 180px);
}

.nav-container {
	display: flex;
}

.nav-container div {
	padding: 10px;
	border: 1px solid black;
	margin-left: 10px;
	margin-bottom: 10px;
}

.intext-list {
	padding-block-start: 0px !important;
	padding-inline-start: 10px !important;
	margin-left: 0px;
}

.project-body {
	overflow: scroll;
	font-size: 0.8em;
	width: 450px;
	margin-right: 20px;
}

.project-gallery {
	overflow: scroll;
	width: 50vw;
	padding: 10px;
}

.project-gallery img {
	max-width: 95%;
}
