.news-container {
	display: flex;
	width: 65vw;
	padding: 10px;
	flex-wrap: wrap;
}

.news-item {
	width: 200px;
	min-height: 200px;
	border: 1px solid black;
	margin: 10px;
	position: relative;
	transition: 0.5s;
	font-size: 0.8em;
	align-self: flex-start;
	background-color: white;
}

.news-item > p {
	padding: 2px;
}

.news-item img {
	width: 100%;
}

.news-item-mobile {
	width: 150px;
	min-height: 150px;
	border: 1px solid black;
	margin: 10px;
	font-size: 0.8em;
	align-self: flex-start;
	background-color: white;
}

.news-container-mobile {
	display: flex;
	width: 95vw;
	padding: 2px;
	flex-wrap: wrap;
}

.news-item-mobile img {
	width: 100%;
}

.news-item-mobile > p {
	padding: 5px;
}


/*.overlay-text {
	position: absolute;
	color: white;
	z-index: 2;
	width: 300px;
}*/