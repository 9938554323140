.too-much {
	transform: rotate(-4deg);
	width: 650px;
	padding: 15px;
	background-color: white;
	color: black;
	z-index: 10;
	position: absolute;
	left: 200px;
	top: 100px;
	height: 75vh;
	overflow: scroll;
	box-shadow:     
	0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #ffe6b3,
    0 0 82px #ffcc66,
    0 0 92px #ffcc66,
    0 0 102px #e69900,
    0 0 151px #e69900;
}

.too-much-mobile {
	transform: rotate(-2deg);
	width: 80vw;
	padding: 10px;
	background-color: white;
	color: black;
	z-index: 10;
	position: absolute;
	left: 30px;
	top: 150px;
	height: 70vh;
	overflow: scroll;
	box-shadow:     
	0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #ffe6b3,
    0 0 82px #ffcc66,
    0 0 92px #ffcc66,
    0 0 102px #e69900,
    0 0 151px #e69900;
}

.too-much-container {
/*	background-color: black;*/
	background: linear-gradient(0deg, rgba(145,0,0,1) 0%, rgba(125,0,110,1) 100%);
	color: white;
	font-family: Times;
	width: 100vw;
	height: 100vh;
	position: fixed;
/*	overflow: scroll;*/
}

.too-much-container h1 {
	font-family: Bespoke-LYw8FRlPxf;
}

.too-much h1 {
	font-family: Bespoke-LYw8FRlPxf;
	text-align: center;
	width: 100%;
}

.too-much .main-div {
	margin: 40px;
	text-align: justify;
	font-size: 1.2em;
}

.too-much p {
	margin-top: 40px;
	font-size: 1em !important;
}

.less-much {
	transform: rotate(-4deg);
	font-size: 4em;
	position: fixed;
	left: 800px;
	top: 50px;
	z-index: 50;
	color: #0099ff;
}

.less-much:hover {
/*	color: #FFAAAA;*/
	cursor: pointer;
	text-shadow:     
	0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #ffe6b3,
    0 0 82px #ffcc66,
    0 0 92px #ffcc66,
    0 0 102px #e69900,
    0 0 151px #e69900;
}

.too-much .project-body {
	height: auto;
	overflow: scroll;
}